<template>
  <h3 style="display: flex; align-items: center; ">
    <span style="flex: 1">输入字段</span>
    <el-icon class="plus" @click="$refs.paramsAdd.call()">
      <Plus/>
    </el-icon>
  </h3>
  <div style="background: #F9FAFB;padding: 10px;text-align: center;color: #687083">
    设置的输入可在工作流程中使用
  </div>
  <ul>
    <li v-for="(item,index) in list" :key="index">
      <div>
        {{ item.name }}
        <span v-if="item.showName !== item.name" style="color: #9AA2B1"> · </span>
        <span v-if="item.showName !== item.name" style="color: #9AA2B1">{{ item.showName }}</span>

        <span v-if="!item.system" style="float: right;margin-top: 4px;margin-right: 10px">
          <el-icon :size="16" @click="$refs.paramsAdd.call(item)"><Edit></Edit></el-icon>
          <el-icon :size="16" @click="del(item)"><Delete></Delete></el-icon>
        </span>
      </div>
    </li>
  </ul>

  <params-add ref="paramsAdd" @callback="addCallback"></params-add>

</template>

<script>
import paramsAdd from "@/components/workflowDesign/node/paramsAdd.vue";

export default {
  components: {
    paramsAdd
  },
  props: {
    node: {
      type: Object,
    }
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.list = this.node.attrs.inputs.slice().reverse()
  },
  methods: {
    addCallback(params) {

      params.forEach(param=>{
        let inputs = this.node.attrs.inputs
        if(inputs.indexOf(param) === -1) {
          inputs.push(param)
        }
      })

      this.list = this.node.attrs.inputs.slice().reverse()
    },
    del(item) {
      let inputs = this.node.attrs.inputs
      inputs.splice(inputs.indexOf(item), 1)
      this.list = this.node.attrs.inputs.slice().reverse()
    }
  }


}
</script>

<style scoped>

ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

li {
  text-align: left;
  padding-left: 40px;
  line-height: 32px;
  cursor: pointer;
  color: #495465;
  border-radius: 6px;
  border: 1px solid #EAECF0;
  box-shadow: 0 1px 1px 0 #EAECF0;
  font-weight: bold;
  position: relative;
  margin-top: 10px;
}

li:hover {
  box-shadow: 0 2px 4px 0 #E9E9EB;
}

li:before {
  content: '{x}'; /* 设置伪元素的内容为'f' */
  position: absolute; /* 绝对定位伪元素 */
  left: 10px; /* 将伪元素放置在列表项的左侧 */
  top: 0; /* 调整伪元素在垂直方向上的位置（根据需要） */
  color: #3973F6; /* 设置'f'符号的颜色 */
  font-size: 14px; /* 设置'f'符号的字体大小 */
  font-weight: normal; /* 可选：设置'f'符号的字体粗细 */
}

.plus {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.plus:hover {
  background: #EAECF0;
}



.el-icon {
  padding: 4px;
}

.el-icon:hover {
  background: #F2F2F2;
  border-radius: 4px;

}

</style>