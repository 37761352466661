<template>

  <el-form label-position="top" style="margin-right: 20px">

    <el-form-item label="数据源">
      <script-edit v-model="attrs.data"  style="width: 100%" :rows="10" :params="params" type="script" :show-full-screen="true"></script-edit>
    </el-form-item>

    <el-form label-position="top">
      <el-form-item label="数据库">
        <el-select v-model="db.type">
          <el-option label="MongoDB" value="mongodb"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="HOST">
        <div style="display: flex;width: 100%">
          <el-input style="flex: 1" v-model="db.host" placeholder="IP"></el-input>
          <el-input-number style="width: 100px;margin-left: 10px" :controls="false" v-model="db.port"
                           placeholder="PORT"></el-input-number>
        </div>
      </el-form-item>


      <el-form-item label="DATABASE">
        <el-input v-model="db.database" placeholder="Database Name"></el-input>
      </el-form-item>

      <el-form-item label="TABLE">
        <el-input v-model="db.table" placeholder="Table Name"></el-input>
      </el-form-item>

      <el-form-item label="USER NAME">
        <el-input v-model="db.username" placeholder="用户名"></el-input>
      </el-form-item>

      <el-form-item label="PASSWORD">
        <el-input v-model="db.password" placeholder="密码"></el-input>
      </el-form-item>
    </el-form>


  </el-form>

</template>

<script>
import {mapStores} from "pinia";
import {useWorkflowStore} from "@/stores/workflow";
import ScriptEdit from "@/components/workflowDesign/node/scriptEdit.vue";

export default {
  computed: {
    ...mapStores(useWorkflowStore)
  },
  components: {ScriptEdit},
  props: {
    node: {
      type: Object,
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      attrs: {},
      params: [],
      isFullScreen: false,
    }
  },
  created() {
    this.attrs = this.node.attrs;
    this.db = this.attrs.db;

    this.params = []
    this.workflowStore.params(this.params, this.node, this.data)
  }


}

</script>


<style scoped>

</style>