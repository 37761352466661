import {defineStore} from 'pinia'
import {useAppStore} from "@/stores/app";
import userController from "@/controller/userController";
import Cookies from 'js-cookie'

const cookiesStorage = {
    setItem(key, state) {
        let myState = JSON.parse(state)
        let value = myState[key];
        return Cookies.set(key, value, {expires: 3})
    },
    getItem(key) {
        let value = Cookies.get(key);
        return JSON.stringify({
            [key]: value,
        })
    }
}

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            token: '',
            user: {},
        }
    },
    actions: {
        validate(username,password){
            return userController.validate({username,password});
        },
        setToken(token) {
            this.token = token;
        },
        setUser(user) {
            this.user = user;
        },
        icon(icon, isRefresh) {
            let appStore = useAppStore();
            if (!isRefresh) {
                return icon ? appStore.resourceUrl(`/static/image${icon}`) : appStore.resourceUrl('/static/image/head/default.png');
            } else {
                return icon ? appStore.resourceUrl(`/static/image   ${icon}?arg=${new Date().getTime()}`) : appStore.resourceUrl('/static/image/head/default.png');
            }
        },
        logout() {
            this.token = ''
            this.user = {};
            window.location.href = "/login";
        },
        relogin(params) {
            userController.reLogin(params).then(({code, data}) => {
                if (code === 200) {
                    this.user = data
                } else {
                    this.token = '';
                }
            })
        },
        initUser() {
            if (this.getToken() && Object.keys(this.getUser()).length === 0) {
                this.relogin()
            }
        },
        getId() {
            return this.user.id;
        },
        getToken() {
            return this.token;
        },
        getUser() {
            return this.user;
        },
    },
    persist: {
        enabled: true,
        strategies: [{key: 'user', paths: ['user'], storage: sessionStorage}, {
            key: 'token',
            paths: ['token'],
            storage: cookiesStorage
        }],
    }
})