import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    children:[
      {
        path: '/workflow',
        name: 'workflow',
        component: () => import(/* webpackChunkName: "about" */ '../views/indexWorkFlow.vue'),
      },
      {
        path: '/workflow-design/:id',
        name: 'workflow-design',
        component: () => import(/* webpackChunkName: "about" */ '../views/indexWorkflowDesign.vue'),
      },
      {
        path: '/configuration',
        name: 'configuration',
        component: () => import(/* webpackChunkName: "about" */ '../views/indexConfiguration.vue'),
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
