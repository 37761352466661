import {get, post, post2, put, put2, $delete, upload} from '@/plugins/axios.js'
import {useAppStore} from "@/stores/app";

export default {
    validate(params) {
        return post('/nocode/user/validate', params);
    },
    reLogin(params) {
        return post('/nocode/user/reLogin', params);
    },
    logout(params) {
        return post('/nocode/user/logout', params);
    },

}