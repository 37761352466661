<template>
<div>
  <el-popover :visible="visible"  width="150px" :show-arrow="false" :popper-style="`top:${top+30}px;left:${left}px;`">

    <template v-for="group in params">
      <div style="background-color: white">
        <div style="color: #697184;font-size: 14px;font-weight: bold;margin-bottom: 10px">{{ group.name }}</div>
        <ul>
          <template v-for="param in group.params">
            <li @click="type==='text'? insert(`\${${param.name}}`):insert(param.name)">
              <span style="padding-left: 10px"> {{ param.showName || param.name }} </span>
            </li>
          </template>
        </ul>
      </div>
    </template>
    <template #reference>
      <div style="width: 100%;position: relative" :class="{ 'fullscreen': isFullScreen }">
        <el-button v-if="showFullScreen" :icon="isFullScreen?'SemiSelect':'FullScreen'" link
                   style="float: right;position: absolute;right: 6px;top: 6px;z-index: 1000"
                   @click="isFullScreen=!isFullScreen"></el-button>
        <el-input ref="input" type="textarea" :size="size" v-model="value" resize="none"
                  :rows="isFullScreen?100:rows"
                  @input="handleInput"
                  @focus="handleFocus" :style="{width:width}"
                  @blur="handleBlur"
                  :placeholder="placeholder"
        ></el-input>
      </div>
    </template>

  </el-popover>

</div>
</template>

<script>
export default {
  emits: ['input', 'focus', 'blur', 'change', 'update:modelValue'],
  props: {
    showFullScreen: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: "small"
    },
    placeholder: {
      type: String,
      default: "键入 / 快速插入变量"
    },
    params: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Number,
      default: 1
    },
    width: {
      type: String,
      default: "100%"
    },
  },
  components: {},
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        this.value = newValue;
      },
      immediate: true
    },
    isFullScreen: {
      handler(newValue, oldValue) {
        if(newValue){
          this.$refs.input.focus();
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      visible: false,
      cursorPos: 0,
      value: this.modelValue,
      top: 0,
      left: 0,
      isFullScreen: false,
      input:this.$refs.input,
    }
  },
  created() {
  },
  methods: {
    handleInput(val) {
      if (val.includes('/')) {
        this.visible = true
      } else {
        this.visible = false
      }

      this.$emit("update:modelValue", this.value);
      this.$emit('input', val)
    },
    handleBlur(e) {
      this.visible = false;
      if (this.value.endsWith("/")) {
        this.value = this.value.substring(0, this.value.length - 1)
        this.$emit('update:modelValue', this.value)
      }
      this.cursorPos = e.srcElement.selectionStart;
      this.$emit('blur', e)
    },
    handleFocus(e) {
      const textarea = e.target;
      const rect = textarea.getBoundingClientRect();
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;

      const top = rect.top + scrollTop;
      const left = rect.left + scrollLeft;

      this.top = top;
      this.left = left;

      this.$emit('focus', e)
    },
    insert(str) {
      if (!this.value) {
        this.value = str;
        this.$emit('update:modelValue', this.value)
      } else {
        let start = this.value.substring(0, this.cursorPos);
        if (start.endsWith("/")) {
          start = start.substring(0, start.length - 1)
        }
        let end = this.value.substring(this.cursorPos);
        this.value = `${start}${str}${end}`;
        this.$emit('update:modelValue', this.value)
      }
    },
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  font-size: 13px;
  text-align: left;
  line-height: 28px;
  cursor: pointer;
  color: #111827;
  display: flex;
  align-items: center;
}

li:before {
  content: '{x}'; /* 设置伪元素的内容为'f' */
  color: #3973F6; /* 设置'f'符号的颜色 */
  font-size: 13px; /* 设置'f'符号的字体大小 */
  font-weight: normal; /* 可选：设置'f'符号的字体粗细 */
}

li:hover {
  background: #F9FAFB;
}

.fullscreen {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  box-sizing: border-box;
}


</style>
