<template>

<params :node="node"></params>

</template>

<script>
import Params from "@/components/workflowDesign/node/params.vue";

export default {
  components: {Params},
  props: {
    node: {
      type: Object,
    },
    data:{
      type:Object
    }
  },
  data(){
    return {
      attrs:{}
    }
  },
  created() {
    this.attrs = this.node.attrs;
  }
}
</script>


<style scoped>

</style>