import {get, post, post2, put, put2, $delete, upload} from '@/plugins/axios.js'
import params from "@/components/workflowDesign/node/params.vue";

export default {
    post(params) {
        return post2('/api/workflow', params);
    },
    list(params) {
        return get('/api/workflow/list', params);
    },
    del(params) {
        return $delete('/api/workflow', params);
    },
    findById(params) {
        return get('/api/workflow/findById', params);
    },
    runTest(params) {
        return post2('/api/workflow/runTest', params);
    },
    logs(params) {
        return get('/api/workflow/logs', params);
    },
}