<template>
  <router-view/>
</template>

<style>

#app{
  width: 100%;
  height: 100%;
}

</style>
