import {defineStore} from 'pinia'
import {useUserStore} from "@/stores/user";
import workflowController from "@/controller/workflowController";
import {$delete} from "@/plugins/axios";

export const useWorkflowStore = defineStore('workflow', {
    state: () => {
        return {
            remarks: ['描述缺失,创意满满', '简述深意，创意无限', '笔墨稀少，创意盈怀', '言简创意，妙趣横生', '略述大概，妙想连篇', '简述大意，创意纷飞'],
        }
    },
    actions: {

        toParam(node) {

            let params = [];

            node.attrs.inputs.forEach(item => {
                params.push({
                    name: item.system ? item.name : node.id + "." + item.name,
                    showName: item.showName,
                    type: item.type
                })
            })

            node.attrs.outs.forEach(item => {
                params.push({
                    name: item.system ? item.name : node.id + "." + item.name,
                    showName: item.showName,
                    type: item.type
                })
            })

            return {
                name: node.name,
                params: params
            };
        },

        params(result, node, data) {
            let nodeList = data.nodeList;
            let lineList = data.lineList;

            let lastId = lineList.find(find => find.to === node.id)?.from || null;
            if (lastId) {
                let lastNode = nodeList.find(find => find.id === lastId);
                if (lastNode) {

                    let params = this.toParam(lastNode);

                    if (params.params.length > 0) {
                        result.push(params)
                    }
                    this.params(result, lastNode, data)
                }
            }
        },
        findNode(id, data) {
            return data.nodeList.find(find => find.id === id);
        },
        post(params) {
            if (!params.id) {
                params.creator = useUserStore().user.real_name;
                params.creatorId = useUserStore().user.id;
            }

            if (!params.remark) {
                params.remark = this.remarks[Math.floor(Math.random() * this.remarks.length)];
            }

            return workflowController.post(params);
        },
        list(params) {
            return workflowController.list(params);
        },
        del(id) {
            return workflowController.del({id});
        },
        findById(id) {
            return workflowController.findById({id});
        },
        runTest(params) {
            return workflowController.runTest(params);
        },
        logs(id){
            return workflowController.logs({id})
        }
    },
})