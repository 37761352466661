import {defineStore} from 'pinia'

export const useAppStore = defineStore('app', {
    state: () => {
        return {
            name: 'Grid workflow',
            version: '1.0.0',
            lastUrl:"",
        }
    },
    getters: {
        getVersion() {
            return this.version;
        }
    },
    actions: {
        resourceUrl(url){
            if(url.startsWith("/")){
                return  `https://nocode.gridssoft.com/api${url}`
            }else{
                return  `https://nocode.gridssoft.com/api${url}`
            }

        },
        setLastUrl(url){
            this.lastUrl = url;
        },
        getLastUrl(){
          return this.lastUrl;
        },
        clearLastUrl(){
            this.lastUrl = '';
        }

    }
})