import './plugins/axios'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import {ElMessage, ElMessageBox, ElNotification} from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import 'dayjs/locale/zh-cn'
import {createPinia} from 'pinia'
import commonJs from "@/assets/common";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import ContextMenu from '@imengyu/vue3-context-menu'
import 'animate.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/assets/var.css'
//codemirror


let app = createApp(App);

app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$messageBox = ElMessageBox;
app.config.globalProperties.$notification = ElNotification;


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import start from "@/components/workflowDesign/node/startComponent.vue";
import http from "@/components/workflowDesign/node/httpComponent.vue";
import share from "@/components/workflowDesign/node/shareComponent.vue"
app.component("start",start)
app.component("http",http)
app.component("share",share)
let pinia = createPinia().use(piniaPluginPersistedstate)

app.use(commonJs).use(pinia).use(ElementPlus, {locale: zhCn,}).use(ContextMenu).use(router).mount('#app')
