<template>

  <el-form label-position="top" label-width="100px">
    <el-form-item label="API">

      <div style="width: 100%;display: flex;align-items: center;">
        <el-select v-model="attrs.method" style="width: 96px">
          <el-option v-for="(item,index) in restful" :key="index" :label="item" :value="item"></el-option>
        </el-select>
        <el-input style="flex: 1;margin-left: 2px" placeholder="输入URL" v-model="attrs.url"></el-input>
      </div>

    </el-form-item>
    <el-form-item label="HEADERS">
      <el-table :data="attrs.headers" border style="border-radius: 6px" size="small"
                :header-cell-style="{color:'#181A1D'}">
        <el-table-column label="键" align="center">
          <template #default="scope">
            <script-edit v-model="scope.row.key" :params="params" style="width: 100%"></script-edit>
          </template>
        </el-table-column>
        <el-table-column label="值" align="center">
          <template #default="scope">
            <div style="display: flex">
              <script-edit v-model="scope.row.value" :params="params" style="flex: 1" @focus="addRow(scope,attrs.headers)"></script-edit>
              <el-button icon="DeleteFilled"  link v-if="isShowDelete(attrs.headers)" @click="delRow(scope,attrs.headers)"
              ></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

    </el-form-item>
    <el-form-item label="PARAMS">
      <el-table :data="attrs.params" border style="border-radius: 6px" size="small"
                :header-cell-style="{color:'#181A1D'}">
        <el-table-column label="键" align="center">
          <template #default="scope">
            <script-edit v-model="scope.row.key" :params="params" style="width: 100%"></script-edit>
          </template>
        </el-table-column>
        <el-table-column label="值" align="center">
          <template #default="scope">
            <div style="display: flex">
              <script-edit v-model="scope.row.value" :params="params" style="flex: 1" @focus="addRow(scope,attrs.params)"></script-edit>
              <el-button icon="DeleteFilled" v-if="isShowDelete(attrs.params)"  link @click="delRow(scope,attrs.params)"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item label="BODY">
      <el-radio-group v-model="attrs.bodyType" style="margin-bottom: 10px">
        <el-radio v-for="(item,index) in body" :key="index" :label="item" :value="item"></el-radio>
      </el-radio-group>

      <el-table v-if="attrs.bodyType==='form-data' || attrs.bodyType==='x-www-form-urlencoded'" :data="attrs.body"
                border style="border-radius: 6px" size="small" :header-cell-style="{color:'#181A1D'}">
        <el-table-column label="键" align="center">
          <template #default="scope">
            <script-edit v-model="scope.row.key" :params="params" style="width: 100%"></script-edit>
          </template>
        </el-table-column>
        <el-table-column label="值" align="center">
          <template #default="scope">
            <div style="display: flex">
              <script-edit v-model="scope.row.value" :params="params" style="flex: 1" @focus="addRow(scope,attrs.body)"></script-edit>
              <el-button icon="DeleteFilled" v-if="isShowDelete(attrs.body)" link @click="delRow(scope,attrs.body)"
              ></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div v-else-if="attrs.bodyType==='json'" style="background-color: #F2F4F7;padding:0 10px 10px 10px;width: 100%;border-radius: 10px">
        <div style="font-size: 13px;font-weight: bold;color: #495465;margin: 0 0 0 4px;">JSON</div>
        <script-edit v-model="attrs.bodyText" :rows="10" :params="params" type="text"  :show-full-screen="true"></script-edit>
      </div>

      <div v-else-if="attrs.bodyType==='raw'" style="background-color: #F2F4F7;width: 100%;padding:0 10px 10px 10px;border-radius: 10px">
        <div style="font-size: 13px;font-weight: bold;color: #495465;margin: 0 0 0 4px;">Raw Text</div>
        <script-edit v-model="attrs.bodyText" :rows="10" :params="params" type="text"  :show-full-screen="true"></script-edit>
      </div>

    </el-form-item>
    <el-form-item label="输出变量">
      body <span style="margin-left: 20px;color: #686F81;">string</span>
    </el-form-item>

  </el-form>

</template>

<script>
import ScriptEdit from "@/components/workflowDesign/node/scriptEdit.vue";
import {mapStores} from "pinia";
import {useWorkflowStore} from "@/stores/workflow";

export default {
  components: {ScriptEdit},
  props: {
    node: {
      type: Object,
    },
    data: {
      type: Object
    }
  },
  computed: {
    ...mapStores(useWorkflowStore)
  },
  data() {
    return {
      attrs: {},
      restful: ['GET', 'POST', 'PUT', 'DELETE'],
      body: ['none', 'form-data', 'x-www-form-urlencoded', 'raw', 'json'],
      params: [],
    }
  },
  created() {
    this.attrs = this.node.attrs;
    this.params = []
    this.workflowStore.params(this.params, this.node, this.data)


    if (this.attrs.headers.length === 0) {
      this.attrs.headers.push({key: '', value: ''});
    }

    if (this.attrs.params.length === 0) {
      this.attrs.params.push({key: '', value: ''})
    }

    if (this.attrs.body.length === 0) {
      this.attrs.body.push({key: '', value: ''})
    }
  },
  methods: {
    addRow(scope, list) {
      if (scope.$index === list.length - 1) {
        list.push({key: '', value: ''})
      }
    },
    delRow(scope, list) {
      list.splice(scope.$index, 1)
      if (list.length===0) {
        list.push({key: '', value: ''})
      }
    },
    isShowDelete(arr){
      return  !(arr.length===1 && !arr[0].key && !arr[0].value);
    }
  },
  unmounted() {
    let flatMap = [this.attrs.headers,this.attrs.params,this.attrs.body]
    flatMap.forEach(item=>{
      let toDelete = [];
      for(let i=0;i<item.length;i++){
        if(!item[i].key && !item[i].value){
          toDelete.push(i);
        }
      }
      toDelete.forEach(index=>item.splice(index,1))
    })
  },


}

</script>

<style scoped>
.el-table {
  :deep(.el-input__wrapper) {
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: 0 0 0 0 black;
    border-radius: 0;
  }
}


</style>