<template>
  <el-dialog title="添加变量" style="border-radius: 10px" width="30%" v-model="dialogVisible"
             :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" append-to-body
  >

    <div>
      <el-segmented v-model="segmented" :options="['基础','JSON']" block/>
      <div style="padding:0 20px;margin-top: 20px">
        <el-form v-if="segmented==='基础'" label-position="top">
          <el-form-item label="字段类型">
            <el-radio-group v-model="form.type">
              <el-radio label="文本" value="string"></el-radio>
              <el-radio label="数字" value="number"></el-radio>
              <el-radio label="对象" value="object"></el-radio>
              <el-radio label="数组" value="array"></el-radio>
              <el-radio label="布尔值" value="boolean"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="变量名称">
            <el-input ref="nameRef" v-model="form.name" placeholder="请输入" @change="()=>{
              if(!form.showName){
                form.showName=form.name;
              }
            }" clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="显示名称">
            <el-input v-model="form.showName" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="值" class="value">
            <code-mirror v-model="form.value" :extensions="[oneDark]" :lang="lang" :linter="linter" basic
                         style="width: 100%;" :dark="true"
            />
          </el-form-item>
          <el-form-item label="必填">
            <el-switch v-model="form.required"></el-switch>
          </el-form-item>
        </el-form>

        <el-form v-if="segmented==='JSON'" label-position="top">
          <el-form-item label="JSON DATA" class="json">
            <code-mirror v-model="json" :extensions="[oneDark]" :lang="lang" :linter="linter" basic style="width: 100%;"
                         :dark="true"
            />
          </el-form-item>
        </el-form>

        <div style="text-align: right;">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" :disabled="!form.name && !json" @click="submit()">保存</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CodeMirror from 'vue-codemirror6';
import {json, jsonParseLinter} from '@codemirror/lang-json';
import {oneDark} from "@codemirror/theme-one-dark";

export default {
  components: {
    CodeMirror
  },
  emits: ['callback'],
  data() {
    return {
      lang: json(),
      linter: jsonParseLinter(),
      oneDark: oneDark,
      segmented: '基础',
      dialogVisible: false,
      form: {},
      json: '',

    }
  },
  mounted() {
  },
  methods: {
    call(form) {
      if (form) {
        this.form = form;
      } else {
        this.form = {
          name: '',
          showName: '',
          type: 'string',
          value: '',
          required: true,
          system: false,
        }
      }

      this.dialogVisible = true;
      this.$nextTick(() => {
        setTimeout(() => {
          try {
            this.$refs.nameRef.focus();
          } catch (e) {
            // Ignore error
          }
        }, 500);
      });
    },
    getType(value) {
      if (value === null) {
        return 'null';
      }

      const type = typeof value;

      if (type !== 'object') {
        return type;
      }

      const toString = Object.prototype.toString;
      const typeString = toString.call(value);

      switch (typeString) {
        case '[object Array]':
          return 'array';
        case '[object Object]':
          return 'object';
        case '[object String]':
          return 'string';
        case '[object Number]':
          return 'number';
        case '[object Boolean]':
          return 'boolean';
        case '[object Function]':
          return 'function';
        case '[object Date]':
          return 'date';
        case '[object RegExp]':
          return 'regexp';
        case '[object Map]':
          return 'map';
        case '[object Set]':
          return 'set';
        case '[object Symbol]':
          return 'symbol';
        case '[object Undefined]':
          return 'undefined';
        default:
          return 'unknown';
      }
    },
    submit() {
      if (this.segmented === '基础') {
        if (!this.form.name) {
          this.message('请填写参数名称', 'error');
          return;
        }

        if (!this.form.showName) {
          this.form.showName = this.form.name;
        }

        this.$emit('callback', [this.form]);
        this.dialogVisible = false;
      } else {

        try {
          let jsonObject = JSON.parse(this.json);
          let forms = Object.keys(jsonObject).map(key => {
            return {
              name: key,
              showName: key,
              type: this.getType(jsonObject[key]),
              required: true,
              system: false,
              value: JSON.stringify(jsonObject[key], null, '\t')
            }
          })

          this.$emit('callback', forms);
          this.json = '';
          this.segmented = '基础';
          this.dialogVisible = false;
        } catch (e) {
          this.message('无效的JSON格式')
        }

      }
    },
  },
}
</script>

<style scoped>
.value {
  :deep(.cm-scroller) {
    height: 100px;
  }
}

.json {
  :deep(.cm-scroller) {
    height: 420px;
  }
}

</style>
