"use strict";

import axios from "axios";
import qs from 'qs'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {useUserStore} from '@/stores/user'

let config = {};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        let token = useUserStore().getToken();
        if (token) config.headers.Authorization = token;
        NProgress.start()

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        NProgress.done()

        if (response.data.code === 401) {
            useAppStore().setLastUrl(window.location.pathname)
            window.location.href = "/";
            if (response.data.message) ElMessage(response.data.message)
        }else{
            return response;
        }


    },
    function (error) {
        return Promise.reject(error);
    }
);

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        _axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        _axios.post(url, qs.stringify(data))
            .then(response => {
                resolve(response.data);
            }).catch(err => {
            reject(err)
        })
    })
}

export function post2(url, data = {}) {
    return new Promise((resolve, reject) => {
        _axios.post(url, data)
            .then(response => {
                resolve(response.data);
            }).catch(err => {
            reject(err)
        })
    })
}

export function upload(url, formData) {
    return new Promise((resolve, reject) => {
        _axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err)
        })
    })
}

export function download(fileName, url, params = {}, beforeCallback, afterCallback) {

    return new Promise((resolve, reject) => {
        if (beforeCallback) beforeCallback()

        _axios({
            headers: {'Content-Type': 'application/json'},
            responseType: 'blob',
            method: 'get',
            url: url,
            params,
            timeout: 60000
        }).then(response => {
            let link = document.createElement('a')
            let blob = new Blob([response.data])
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            if (afterCallback) afterCallback()
        }).catch(err => {
            reject(err)
        })
    });
}

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        _axios.put(url, data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    });
}

export function put2(url, params) {
    return _axios({
        method: 'put',
        url: url,
        data: qs.stringify(params)
    })
}

export function $delete(url, params) {
    return new Promise((resolve, reject) => {
        return _axios({
            method: 'delete',
            url,
            params,
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    });
}

